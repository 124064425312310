@font-face {
  font-family: 'BT Font';
  src: url('assets/fonts/BTFont-Regular.eot');
  src: url('assets/fonts/BTFont-Regular.eot?#iefix') format('embedded-opentype'), url('assets/fonts/BTFont-Regular.woff2') format('woff2'), url('assets/fonts/BTFont-Regular.woff') format('woff'), url('assets/fonts/BTFont-Regular.ttf') format('truetype'), url('assets/fonts/BTFont-Regular.svg#BTFont-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BT Font';
  src: url('assets/fonts/BTFont-Bold.eot');
  src: url('assets/fonts/BTFont-Bold.eot?#iefix') format('embedded-opentype'), url('assets/fonts/BTFont-Bold.woff2') format('woff2'), url('assets/fonts/BTFont-Bold.woff') format('woff'), url('assets/fonts/BTFont-Bold.ttf') format('truetype'), url('assets/fonts/BTFont-Bold.svg#BTFont-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body,
h1,
p,
ul,
li,
div {
  font-family: 'BT Font';
  font-weight: normal;
  font-style: normal;
}

h2,
h3,
h4,
h5,
strong,
b,
figure.image figcaption {
  font-family: 'BT Font';
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.show>.btn-primary.dropdown-toggle {
  background-color: #fff;
  color: #000;
}

.btn-primary:hover {
  border-color: transparent;
}

.mgrpdf__wrapper {
  max-width: 100%;
  height: auto;
}

.mgrpdf__wrapper canvas,
figure.image.image_resized img {
  max-width: 100%;
  height: auto !important;
}

.nav {
  flex-wrap: nowrap;
}

.fBJxAH,
.xZtEq {
  padding: 15px 30px !important;
}

.jcqGXt {
  display: block !important;
}

.jcqGXt p {}

.iwisNA {
  font-size: 17px !important;
}

.jjTcTs,
.jARayQ {
  column-gap: 55px !important;
}

.search-img {
  overflow: hidden;
  height: 200px;
}

.image-style-align-right {
  float: right;
}

figure.image figcaption {
  text-align: center;
  color: rgb(85, 20, 180);
}

img.sc-jJMGnK.laKGqb {
  cursor: pointer;
}

.fqbJCS,
.eJDRKz,
#section1882 .dGJRan,
#section1883 .dGJRan,
#section1884 .dGJRan,
#section1885 .dGJRan {
  column-count: 1 !important;
}

h1 {
  font-size: 32px;
  color: rgb(85, 20, 180);
}

.jjTcTs,
.jARayQ {
  padding-top: 0 !important;
}

.btn-cdg {
  padding-top: 8px;
  padding-right: 10px;
  margin-bottom: 15px;
  margin-right: 15px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: rgb(0, 0, 0);
  border-right-color: rgb(0, 0, 0);
  border-bottom-color: rgb(0, 0, 0);
  border-left-color: rgb(0, 0, 0);
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: rgb(230, 0, 80);
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 14px;
  position: relative;
  max-width: 350px;
  width: 250px;
  height: 44px;
  text-align: left;
}

.btn-lg {
  max-width: 45%;
  width: 100%;
}

.productDetailsModel {
  padding: 0;
}

.productDetailsModel .modal-dialog {
  max-width: 700px;
}

.smallModel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.smallModel .modal-dialog {
  max-width: 375px;
  width: 100%;
}
@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: calc(100% - 30px);
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: calc(100% - 30px);
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1370px;
  }
}

@media (max-width:1200px) {

  .main-menu .dropdown button,
  .main-menu div div>a {
    font-size: 14px;
    line-height: 1.3;
  }
}

@media (max-width:768px) {
  .nav-item a {
    padding: 10px;
  }
}